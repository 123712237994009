<template>
  <b-container fluid>
    <bo-page-title />
    <div class="dashboard">     
      <b-row class="gutter-2">
        <b-col lg="3">
          <div class="card_dashboard bg_card_1">
            <b-row>
              <b-col lg="4">
                <div class="wrap_ic_dsb">
                  <i class="ti-layout-list-thumb"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>{{ total_prospect }}</h3>
                  <p>Total Prospect</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col lg="3">
          <div class="card_dashboard bg_card_3">
            <b-row>
              <b-col lg="4">
                <div class="wrap_ic_dsb">
                  <i class="ti-layout-list-thumb"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>{{ total_survey }}</h3>
                  <p>Total Survey</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col lg="3">
          <div class="card_dashboard bg_card_2">
            <b-row>
              <b-col lg="4">
                <div class="wrap_ic_dsb">
                  <i class="ti-layout-list-thumb"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>{{ total_customer }}</h3>
                  <p>Total Customers</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col lg="3">
          <div class="card_dashboard bg_card_4">
            <b-row>
              <b-col lg="4">
                <div class="wrap_ic_dsb">
                  <i class="ti-layout-list-thumb"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>{{ total_running }}</h3>
                  <p>Running Projects</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col lg="3">
          <div class="card_dashboard bg_card_5">
            <b-row align-v="center">
              <b-col lg="4">
                <div class="wrap_ic_dsb">
                  <i class="ti-layout-list-thumb"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>{{ followup.total }} <small class="fs-16 ml-1">({{ followup.has_followup }}/{{ followup.not_followup }})</small></h3>
                  <p>Follow Up</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col lg="3">
          <div class="card_dashboard bg_card_1">
            <b-row align-v="center">
              <b-col lg="4">
                <div class="wrap_ic_dsb">
                  <i class="ti-layout-list-thumb"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>{{ total_finished }}</h3>
                  <p>Finished Projects</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col md="6">
          <b-card class="mb-0 h-100">
            <b-row>
              <b-col md="6">
                <div>
                  <span>Cash in Bank</span>
                  <div class="revenue-count">
                    <h3 class="font-weight-bold mb-0">{{formatRupiah(cash_in_bank || 0)}}</h3>
                  </div>
                </div>
              </b-col>
              <b-col md="6" class="border-left">
                <div>
                  <span>Accounts Receivable</span>
                  <div class="revenue-count">
                    <h3 class="font-weight-bold mb-0">{{formatRupiah(total_accounts_receivable || 0)}}</h3>
                  </div>
                  <b-row class="mt-2">
                    <b-col md=6>
                      <p class="mb-0">AR:</p>
                      <p><strong>{{formatRupiah(accounts_receivable || 0)}}</strong></p>
                    </b-col>
                    <b-col md=6 class="border-left">
                      <p class="mb-0">Bailout:</p>
                      <p><strong>{{formatRupiah(bailout || 0)}}</strong></p>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col> 
        <b-col md=6>
          <b-card class="mb-0">
            <template #header>
              <div class="d-flex justify-content-end">
                <v-select v-model="filter.st_card1" placeholder="Select Show Type" :options="[{label: 'Periodically', value: 'P'}, {label: 'Monthly', value: 'M'}]" :reduce="v => v.value" style="width: 180px;" @input="doFilter()">
                </v-select>
              </div>              
            </template>
            <b-row>
              <b-col md="6" v-if="target_bulan">
                <div class="d-flex flex-column justify-content-around h-100">
                  <div>

                    <span>Revenue of {{monthYearNow(filter.st_card1) | moment('MMMM YYYY')}} <strong :class="target_bulan.is_achieve ? 'text-success' : 'text-danger'">({{ target_bulan.percentage }}%)</strong></span>
                    <h3 class="font-weight-bold m-0 position-relative">
                      {{formatRupiah(target_bulan.mt_achieve || 0)}}

                      <small class="position-absolute target-text">Target: {{formatRupiah(target_bulan.mt_target || 0)}}</small>
                    </h3>


                    <template v-if="target_tahun">                                              
                      <div class="mt-5">
                        <span>Revenue of {{ yearNow }} <strong :class="target_tahun.is_achieve ? 'text-success' : 'text-danger'">({{ target_tahun.percentage }}%)</strong> :</span>                          
                        <h4 style="font-weight: 500;">{{formatRupiah(target_tahun['mt_achieve'] || 0)}}</h4>
                      </div>
                    </template>
                  </div>
                </div>
              </b-col>
              
              <b-col md="6" class="border-left">
                <div class="d-flex flex-column justify-content-around h-100">
                  <div>
                    <span>Omzet of {{ monthYearNow(filter.st_card1) | moment('MMMM YYYY') }}</span>
                    <div class="revenue-count">
                      <h3 class="font-weight-bold">{{formatRupiah(omzet.month || 0)}}</h3>

                      <div class="mt-5">
                        <span>Omzet of {{ yearNow }}:</span>
                        <h4 style="font-weight: 500;">{{formatRupiah(omzet.year || 0)}}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>          
        </b-col>

        <b-col md=6>
          <b-card class="mb-0">
            <template #header>
              <div class="d-flex justify-content-end">
                <v-select v-model="filter.st_card2" placeholder="Select Show Type" :options="[{label: 'Periodically', value: 'P'}, {label: 'Monthly', value: 'M'}]" :reduce="v => v.value" style="width: 180px;" @input="doFilter()">
                </v-select>
              </div>              
            </template>
            <b-row>              
              <b-col md="6">
                <div class="d-flex flex-column justify-content-around h-100">
                  <div>
                    <span>Income of {{ monthYearNow(filter.st_card2) | moment('MMMM YYYY') }}</span>
                    <div class="revenue-count">
                      <h3 class="font-weight-bold">{{formatRupiah(income.month || 0)}}</h3>

                      <div class="mt-5">
                        <span>Income of {{ yearNow }}:</span>
                        <h4 style="font-weight: 500;">{{formatRupiah(income.year || 0)}}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="6" class="border-left">
                <div class="d-flex flex-column justify-content-around h-100">
                  <div>
                    <span>Expenses of {{ monthYearNow(filter.st_card2) | moment('MMMM YYYY') }}</span>
                    <div class="revenue-count">
                      <h3 class="font-weight-bold">{{formatRupiah(expense.month || 0)}}</h3>

                      <div class="mt-5">
                        <span>Expenses of {{ yearNow }}:</span>
                        <h4 style="font-weight: 500;">{{formatRupiah(expense.year || 0)}}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>          
        </b-col>
      </b-row>      
      <div class="mt-3">
        <b-card no-body header-class="border-0">
          <template #header>
            <h5 class="card-title">Monthly Revenue</h5>
          </template>
          <VCharts :option="revenueChart" class="chart" />
        </b-card>
      </div>
      <b-card no-body>
        <template #header>
          <h5 class="card-title">Upcoming Survey Schedules</h5>
        </template>
        <b-table
          :fields="schFields"
          :items="upcoming_schedule.data || []"
          :busy="!upcoming_schedule.data"
          responsive
          hover
          show-empty
          striped
        >
        <template #empty>
          <div class="text-center">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No Upcoming Schedule listed yet</span></h4>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-3 text-info"></b-spinner>
            <strong class="text-info">Loading...</strong>
          </div>
        </template>
          <template #cell(number)="v">
            {{(upcoming_schedule.per_page*(upcoming_schedule.current_page-1))+v.index+1}}
          </template>
          <template #cell(tss_date)="data">
            {{data.value | moment('LLL')}}
          </template>
          <template #cell(tp_client_name)="data">
            <div><small>{{ data.item.tp_code }}</small></div>
            <div>{{ data.value }} - {{ data.item.tp_client_type == 'residencial' ? 'Residential' : 'Company' }}</div>
            <a v-if="data.item.tp_client_email" :href="'mailto: '+data.item.tp_client_email" class="contact-info"><i class="ti-email"></i><span>{{ data.item.tp_client_email }}</span></a>
            <a v-if="data.item.tp_client_phone" :href="'https://web.whatsapp.com/send?phone='+data.item.tp_client_phone.replace('08', '628')" target="child" class="contact-info"><i class="fab fa-whatsapp"></i><span>{{ data.item.tp_client_phone }}</span></a>
          </template>
          <template #cell(tss_address)="data">
            <div><small>PIC: {{ data.item.tss_pic_name }}</small></div>
            <div>{{ data.value }}</div>
          </template>
          <template #cell(tss_status)="data">
            <b-badge
              :variant="surveyBadge[data.value].color"
            >
              {{ surveyBadge[data.value].word }}
            </b-badge>
          </template>

          <template #cell(action)="data">
            <b-button
                class="btn-icon"
                variant="outline-info"
                v-b-tooltip.hover="'View Data'"
                :to="{ name: 'BoSurveySchedule', query: { search: data.item.tp_code } }"
              ><i class="fas fa-eye"></i>
            </b-button>
          </template>
        </b-table>
        <template #footer v-if="upcoming_schedule.total > numberShow">
          <b-row class="justify-content-center">
            <b-button type="button" @click="showAllSurvey" variant="transparent" class="btn-rounded text-primary">Show All</b-button>
          </b-row>
        </template>
      </b-card>
      <b-card no-body>
        <template #header>
          <h5 class="card-title">Follow Up Today</h5>
        </template>
        <b-table
          :fields="followUpField"
          :items="followUp || []"
          :busy="!followUp"
          responsive
          hover
          show-empty
          striped
        >
        <template #empty>
          <div class="text-center">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No Follow Up Today listed yet</span></h4>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-3 text-info"></b-spinner>
            <strong class="text-info">Loading...</strong>
          </div>
        </template>
          <template #cell(number)="v">
            {{v.index+1}}
          </template>
          <template #cell(tp_client_name)="data">
            <div><small>{{ data.item.tp_code }}</small></div>
            <div>{{ data.value }} - {{ data.item.tp_client_type == 'residencial' ? 'Residential' : 'Company' }}</div>
            <a v-if="data.item.tp_client_email" :href="'mailto: '+data.item.tp_client_email" class="contact-info"><i class="ti-email"></i><span>{{ data.item.tp_client_email }}</span></a>
            <a v-if="data.item.tp_client_phone" :href="'https://web.whatsapp.com/send?phone='+data.item.tp_client_phone.replace('08', '628')" target="child" class="contact-info"><i class="fab fa-whatsapp"></i><span>{{ data.item.tp_client_phone }}</span></a>
          </template>
          <template #cell(tfu_date)="data">
            {{ data.value | moment('LL') }}
          </template>
          <template #cell(tp_client_address)="data">
            <div>{{ data.value }}</div>
          </template>
          <template #cell(tp_status)="data">
            <b-badge :variant="prospectBadge[data.value].color">
              {{ prospectBadge[data.value].word }}
            </b-badge>
          </template>

          <template #cell(action)="data">
            <b-button
                class="btn-icon"
                variant="outline-info"
                v-b-tooltip.hover="'View Data'"
                :to="{ name: 'BoProspect', query: { search: data.item.tp_code } }"
              ><i class="fas fa-eye"></i>
            </b-button>
          </template>
        </b-table>
      </b-card>
    </div>

    <b-modal id="allSurvey" title="Upcoming Survey Schedules" ok-only ok-title="Close" size="xl">
      <b-card no-body>
        <b-table
          :fields="schFields"
          :items="allSurvey || []"
          :busy="!allSurvey"
          responsive
          hover
          show-empty
          striped
        >
        <template #empty>
          <div class="text-center">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No Upcoming Schedule listed yet</span></h4>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-3 text-info"></b-spinner>
            <strong class="text-info">Loading...</strong>
          </div>
        </template>
          <template #cell(number)="v">
            {{+v.index+1}}
          </template>
          <template #cell(tss_date)="data">
            {{data.value | moment('LLL')}}
          </template>
          <template #cell(tp_client_name)="data">
            <div><small>{{ data.item.tp_code }}</small></div>
            <div>{{ data.value }} - {{ data.item.tp_client_type == 'residencial' ? 'Residential' : 'Company' }}</div>
            <a v-if="data.item.tp_client_email" :href="'mailto: '+data.item.tp_client_email" class="contact-info"><i class="ti-email"></i><span>{{ data.item.tp_client_email }}</span></a>
            <a v-if="data.item.tp_client_phone" :href="'https://web.whatsapp.com/send?phone='+data.item.tp_client_phone.replace('08', '628')" target="child" class="contact-info"><i class="fab fa-whatsapp"></i><span>{{ data.item.tp_client_phone }}</span></a>
          </template>
          <template #cell(tss_address)="data">
            <div><small>PIC: {{ data.item.tss_pic_name }}</small></div>
            <div>{{ data.value }}</div>
          </template>
          <template #cell(tss_status)="data">
          <b-badge
            :variant="surveyBadge[data.value].color"
          >
            {{ surveyBadge[data.value].word }}
          </b-badge>
        </template>
        </b-table>
      </b-card>
    </b-modal>    
  </b-container>
</template>
<style lang="scss" scoped>
  .chart{
    height: 480px;
  }

  .w150 {
    width: 150px;
  }

  .fr h2 {
    font-weight: 500;
  }

  .target-text {
    font-weight: 400;
    bottom: -1.6em;
    left: 0;
    font-size: 13px;
  }

  .fs-16 {
    font-size: 16px;
  }
</style>
<script>
import GlobalVue from '../../libs/Global.vue'
import VCharts from 'vue-echarts'
import Gen from '@/libs/Gen'
import { use } from 'echarts/core'
import {
 SVGRenderer
} from 'echarts/renderers'
import {
  BarChart, PieChart, LineChart
} from 'echarts/charts'
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components'

use([
  SVGRenderer,
  BarChart,
  PieChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
])

export default {
  extends: GlobalVue,
  data() {
    return {
      series: {},
      numberShow: 0,
      schFields: [
        {
          key: 'number', label: '#', 
        },
        {
          key: 'tp_client_name', label: 'Customer Name',
        },
        {
          key: 'tss_date', label: 'Survey Date',
        },
        {
          key: 'tss_address', label: 'Survey Adress', 
        },
        {
          key: 'tp_keperluan', label: 'Purpose', 
        },
        {
          key: 'tss_status', label: 'Status', 
        },
        {
          key: 'action', label: 'Action'
        }
      ],
      followUpField: [
        {
          key: 'number', label: '#'
        },
        {
          key: 'tp_client_name', label: 'Client Name',
        },
        {
          key: 'tfu_date', label: 'Date',
        },
        {
          key: 'tp_client_address', label: 'Address', 
        },
        {
          key: 'tp_keperluan', label: 'Purpose', 
        },
        {
          key: 'action', label: 'Action'
        }
      ],
      upcoming_schedule: [],
      target_tahun: {},
      target_bulan: {},
      total_prospect: 0,
      total_running: 0,
      total_customer: 0,
      total_survey: 0,
      total_followup: 0,
      total_finished: 0,
      expense_year: 0,
      expense_month: 0,
      expense: {},
      followup: {},
      cash_in_bank: 0,
      accounts_receivable: 0,
      total_accounts_receivable: 0,
      bailout: 0,
      omzet: {},
      income: {},
      yearNow: this.$route.query.year || new Date().getFullYear(),
      followUp: [],
      allSurvey: [],
      yearFilter: [],
      filter: {
        st_card1: "P",
        st_card2: "P"
      },
      startRevenue: ""
    }
  },
  computed: {
    stockChart() {
      return this.$refs.stockChart
    },    
    revenueChart(){
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: data => {
            function round(num, decimalPlaces = 0) {
                var p = Math.pow(10, decimalPlaces)
                var n = (num * p) * (1 + Number.EPSILON)
                return Math.round(n) / p
            }

            let percentData = false
            if(data[1] && data[2]){
              if(data[1].seriesName == 'Revenue' && data[2].seriesName == 'Target Revenue'){
                percentData = round((data[1].data/data[2].data)*100, 2)
              }
            }

            let text = ''
            if(data && data[0]){
              text = `<div class="mb-2"><strong>${data[0].name+' '+this.yearNow}</strong></div>
                <div class="tooltip-content">`
              text += `<div class="d-flex mb-1">
                    <div class="mr-1">
                      ${data[0].marker}
                    </div>
                    <div>
                      ${data[0].seriesName}
                      <strong class="text-right d-block">
                        ${this.formatRupiah(data[0].data)}
                      </strong>
                    </div>
                  </div>`
            }
            if(data && data[1]){
              text += `<div class="d-flex mb-1">
                    <div class="mr-1">
                      ${data[1].marker}
                    </div>
                    <div>
                      ${data[1].seriesName}
                      <strong class="text-right d-block ${data[1].data >= data[2].data ? 'text-success' : 'text-danger'}">
                        ${this.formatRupiah(data[1].data)} ${percentData !== false ?  '('+ percentData + '%)' : ''}
                      </strong>
                    </div>
                  </div>`
            }
            if(data && data[2]){
              text += `<div class="d-flex">
                  <div class="mr-1">
                    ${data[2].marker}
                  </div>
                  <div>
                    ${data[2].seriesName}
                    <strong class="text-right d-block">
                      ${this.formatRupiah(data[2].data)}
                    </strong>
                  </div>
                </div>`
            }
            if(data && data[3]){
              text += `<div class="d-flex">
                  <div class="mr-1">
                    ${data[3].marker}
                  </div>
                  <div>
                    ${data[3].seriesName}
                    <strong class="text-right d-block">
                      ${this.formatRupiah(data[3].data)}
                    </strong>
                  </div>
                </div>`
            }

            text += '</div>'
            return text
          }
        },
        legend: {
          data: ['Omzet', 'Revenue', 'Target Revenue', 'Income']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            data: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].slice(0, new Date().getMonth() + 1)
          }
        ],
        yAxis: [
          {
            type: 'value',
          }
        ],
        series: this.series
      }
    }    
  },
  methods: {
    showAllSurvey(){
      Gen.apiRest('/get/' + this.modulePage + '/getAll').then(res => {
        this.allSurvey = res.data.allSurvey
        this.$bvModal.show('allSurvey')
      })
    },
    monthYearNow(type="M"){
      const date = new Date()
      date.setFullYear(this.$route.query.year || new Date().getFullYear())

      if(date.getDate() >= +this.startRevenue && type == "P"){
        if(date.getDate() >= 30 ) date.setDate(28)
        
        date.setMonth(this.$route.query.month ? (this.$route.query.month - 1) : new Date().getMonth() + 1)
      } else {
        date.setMonth(this.$route.query.month ? (this.$route.query.month - 1) : new Date().getMonth())
      }
      return date
    },
  },
	components: {
   VCharts,
	},
  mounted() {
    this.apiGet()   
    
    setTimeout(() => { 
      this.$set(this, 'filter', {...this.filter, st_card1: "P", st_card2: "P"}) 
    }, 700)
  },
  watch:{
    $route(){
      this.apiGet()  
    },
    'yearNow'(v){
      this.filter.year = v

      this.doFilter()
    }
  },
}
</script>